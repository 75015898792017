import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Rooms from "./pages/Rooms";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import RoomDetails from "./pages/RoomDetails";
import Checkout from "./pages/Checkout";
import { ClientSideEndpoint } from "./utility/endpoint";
import Profile from "./pages/Profile";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRooms } from "store/slices/RoomReducer";
import { AppDispatch } from "store";
import ScrollToTop from "layouts/ScrollToTop";
import Policy from "pages/Policy";
import Post from "pages/Post";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getRooms());
  }, [dispatch]);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Routes>
        <Route path={ClientSideEndpoint.default} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={ClientSideEndpoint.rooms} element={<Rooms />} />
          <Route path={ClientSideEndpoint.roomDetails} element={<RoomDetails />}/>
          <Route path={ClientSideEndpoint.aboutUs} element={<About />} />
          {/* <Route path={ClientSideEndpoint.checkout} element={<Checkout />} /> */}
          <Route path={ClientSideEndpoint.contact} element={<Contact />} />
          {/* <Route path={ClientSideEndpoint.blog} element={<Blog />} /> */}
          {/* <Route path={ClientSideEndpoint.login} element={<Login />} /> */}
          {/* <Route path={ClientSideEndpoint.signup} element={<Signup />} /> */}
          {/* <Route path={ClientSideEndpoint.userProfile} element={<Profile />} /> */}
          <Route path={ClientSideEndpoint.NotFound} element={<Error />} />
          <Route path={ClientSideEndpoint.policy} element={<Policy />} />
          <Route path={ClientSideEndpoint.post} element={<Post />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
}

export default App;
