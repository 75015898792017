import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from "swiper/types";
import { useEffect, useRef, useState } from 'react';
import data from '../assets/room_details.json'
import { Button, Modal, Card } from "react-bootstrap";
import 'swiper/css/navigation';
const ClientReview = () => {

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const swiperRef = useRef<any>(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [currentReview, setCurrentReview] = useState(0);
    const handleShow = (index: number) => {
        setCurrentReview(index)
        setShow(true)
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.on('autoplay', () => {
                if (swiperRef.current) {
                    // TO DO: When we added Dynamic data then 4 =>> data.length
                    setSelectedIndex(swiperRef.current.swiper.realIndex % 4);
                }
            });
            swiperRef.current.swiper.on('loopFix', () => {
                if (swiperRef.current) {
                    setSelectedIndex(swiperRef.current.swiper.realIndex % 4);
                }
            });
            swiperRef.current.swiper.on('slideChange', () => {
                if (swiperRef.current) {
                    setSelectedIndex(swiperRef.current.swiper.realIndex % 4);
                }
            });
        }
    }, [selectedIndex]);

    const handleActiveIndexChange = (swiper: SwiperType) => {
        setSelectedIndex(swiper.activeIndex);
    };

    return (
        <>
            <div id="what-client-say" >
                <div className="container">
                    <div className="title1">WHAT GUESTS SAY</div>
                    <div className="title2">Below are a few reviews that brought some light to our business.</div>
                    <Swiper
                        spaceBetween={28}
                        ref={swiperRef}
                        loop={true}
                        centeredSlides={true}
                        slidesPerView={1}
                        onSlideChange={handleActiveIndexChange}
                        breakpoints={{
                            700: {
                                slidesPerView: 3
                            }
                        }}
                        autoplay={{
                            delay: 15000,
                            disableOnInteraction: false,
                        }}
                        /* navigation={true} */
                        modules={[Autoplay]}>
                        {data.reviews.map((review, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial-wrapper">
                                    <div className="testimonial-inner">
                                        <div className="testimonial clearfix">
                                            <div className={`testimonial-caption `}>
                                                {review.review.slice(0, 150)}<a className='pointer' style={{ color: '#fab100' }} onClick={() => handleShow(review.index)}>...Read More</a>
                                            </div>
                                            <div className="author clearfix">
                                                <figure><img src="./images/user.png" alt="" className="img-fluid" />
                                                </figure>
                                                <div className="caption">
                                                    <div className="txt1" ><a style={{ color: '#fab100' }}  onClick={() => handleShow(review.index)} >{review.name}</a> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <Modal show={show} onHide={handleClose} animation={false} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body>
                        <Card.Body>
                            <Card.Title>{data && data.reviews && data.reviews.find(x => x.index == currentReview)?.name}</Card.Title>
                            <div className='mt-2'>
                            <i style={{ color: '#fab100' }} className="fa fa-star"></i>
                            <i style={{ color: '#fab100' }} className="fa fa-star"></i>
                            <i style={{ color: '#fab100' }} className="fa fa-star"></i>
                            <i style={{ color: '#fab100' }} className="fa fa-star"></i>
                            <i style={{ color: '#fab100' }} className="fa fa-star"></i>
                            </div>
                            <Card.Text className='mt-2'>
                                { data.reviews.find(x => x.index == currentReview)?.review}
                            </Card.Text>
                        </Card.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default ClientReview;