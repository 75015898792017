import { Link } from 'react-router-dom';
import logo from '../styles/images/logonew.png'
import { ClientSideEndpoint } from 'utility/endpoint';
import { Collapse, Divider, ConfigProvider } from 'antd';
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const component = (
    <>
    <div className="contact1">
                                        <div className="txt1">MORE PLACES AROUND THE INN AT OCEAN GROVE</div>
                                        <ul className="best1 clearfix">
                                            <li><a href="https://www.oceangrove.org/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/welcome_to_ocean_grove.webp" alt="ocean_grove" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://www.oceangrove.org/sundays" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/auditorium.webp" alt="" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://wonderbarasburypark.com/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/wonderbar.webp" alt="" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://www.silverballmuseum.com/asbury-park/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/pinball.webp" alt="" className="img-fluid" /></a>
                                            </li>

                                        </ul>
                                    </div>
    </>);
const Footer = () => {

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Collapse: {
                          
                        },
                    },
                    token: {
                        // Seed Token
                        colorPrimary: '#00b96b',
                        borderRadius: 4,
                        colorText: '#fff',
                        // Alias Token
                        colorBgContainer: '#f6ffed',
                    },
                }}
            >
                <div className="bot1-wrapper">
                    <div className="sticky-right">
                        <a style={{ borderTopColor: 'white' }} href="#" className="icon icon-primary icon-md btn btn-icon-only  border   animate-up-3">
                            <span className="fas fa-chevron-up" />
                        </a>
                    </div>
                    <div className="container">
                        <div className="bot1 clearfix" style={{padding: "20px 20px"}}>
                            <div className="logo2_wrapper">
                                {/* <Link to='/' className="logo2">
                                <img src={logo} alt="" className="img-fluid" />
                            </Link> */}
                            {/* style={{maxHeight:'50px',color:"white",}} */}
                                <div  ><h3 style={{ color: "white", fontWeight: 'bold',fontFamily: "Dancing Script, cursive",fontStyle: "normal",textTransform: "none" }}>The Inn at Ocean Grove</h3> </div>
                            </div>
                            <div className="social2-wrapper"style={{padding: "0px 0px"}} >
                                <ul className="social2">
                                    <li><a href="https://www.facebook.com/TheInnatOceanGrove/" target='_blank'><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-google-plus"></i></a></li> */}
                                    {/* <li><a href="#"><i className="fa fa-pinterest"></i></a></li> */}
                                </ul>
                            </div>
                            <p>
                                <i>Ocean Grove is known for its picturesque beaches and beautiful scenery, so our enviable location is our first amenity.<br />We're a cozy bed and breakfast that focuses on the comfort and convenience of our guests.
                                </i>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bot1-wrapper">
                   
                   <div className="container">
                       <div className="bot1 clearfix" style={{ padding: "10px 10px" }}>
                       <a style={{textDecoration:'none',color:'#A4AEB0'}} href="https://www.google.com/maps/place/27+Webb+Ave,+Ocean+Grove,+NJ+07756/@40.2104923,-74.0059811,16z/data=!3m1!4b1!4m6!3m5!1s0x89c226482f76e5dd:0x92c34309bdeaf3fc!8m2!3d40.2104923!4d-74.0059811!16s%2Fg%2F11b8v5nfbc?entry=ttu">27 Webb Ave Ocean Grove NJ, 07756</a>       |   <a href="tel:7327758847">(732) 775-8847</a> |  <a href="mailto:Info@TheInnAtOceangrove.com">Info@TheInnAtOceangrove.com</a> | <b>Hours:</b> Mon - Sun : 09:00 am – 11:45 pm
                       </div>
               <Divider style={{ margin: '0px 0px' }} />
                   </div>
               </div>
                {/* <div className="bot2-wrapper">
                    <div className="container">
                        <div className="bot2 clearfix" style={{padding:'10px 0px 10px'}}>
                            <div className="row">
                                <div className="col">
                                 <div className="bot2-title" style={{padding:'0px 0px'}}>Contact Info</div> 

                                    <Collapse bordered={false} ghost={true}
                                        items={[{ key: '1', label: 'REACH OUT TO US', children:<><b>A:</b> 27 Webb Ave Ocean Grove NJ, 07756 | <b>P:</b>  <a href="tel:7327758847">(732) 775-8847</a> |  <b>E:</b> <a href="mailto:Info@TheInnAtOceangrove.com">Info@TheInnAtOceangrove.com</a></> }]}
                                    />
                                    <Divider style={{margin:'0px 0px'}} />
                                    <Collapse bordered={false} ghost={true}
                                        items={[{ key: '1', label: 'FRONT DESK HOURS', children: <><div className="txt1">Mon - Sun : 09:00 am – 11:45 pm</div></> }]}
                                    />
                                    <Divider style={{margin:'0px 0px'}} />
                                    <Collapse bordered={false} ghost={true}
                                        items={[{ key: '1', label: 'MORE PLACES AROUND THE INN AT OCEAN GROVE', children: <>{component}</> }]}
                                    />
                                    <div className="contact1">
                                        <b>A:</b> 27 Webb Ave Ocean Grove NJ, 07756 | <b>P:</b>  <a href="tel:7327758847">(732) 775-8847</a> |  <b>E:</b> <a href="mailto:Info@TheInnAtOceangrove.com">Info@TheInnAtOceangrove.com</a>
                                    </div> 
                                   <div className="contact1">
                                        <div className="txt1">Mon - Sun : 09:00 am – 11:45 pm</div>
                                    </div>
                                    <div className="contact1">
                                        <div className="txt1">MORE PLACES AROUND THE INN AT OCEAN GROVE</div>
                                        <ul className="best1 clearfix">
                                            <li><a href="https://www.oceangrove.org/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/welcome_to_ocean_grove.webp" alt="ocean_grove" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://www.oceangrove.org/sundays" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/auditorium.webp" alt="" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://wonderbarasburypark.com/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/wonderbar.webp" alt="" className="img-fluid" /></a>
                                            </li>
                                            <li><a href="https://www.silverballmuseum.com/asbury-park/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/pinball.webp" alt="" className="img-fluid" /></a>
                                            </li>

                                        </ul>
                                    </div> 
                                </div>
                                <div className="col-md-4">
                                <div className="bot2-title">Front Desk Hours</div>
                                <div className="recent-post clearfix">
                                    <Link to={ClientSideEndpoint.post}>
                                        <div className="caption">
                                            <div className="txt1">Mon - Sun </div>
                                            <div className="txt2">09:00 am – 11:45 pm</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bot2-title">MORE PLACES AROUND THE INN AT OCEAN GROVE</div>
                                <ul className="best1 clearfix">
                                    <li><a href="https://www.oceangrove.org/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/welcome_to_ocean_grove.webp" alt="ocean_grove" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="https://www.oceangrove.org/sundays" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/auditorium.webp" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="https://wonderbarasburypark.com/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/wonderbar.webp" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="https://www.silverballmuseum.com/asbury-park/" target='_blank'><img style={{ maxHeight: "70px" }} src="./images/footer/pinball.webp" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="#" target='_blank'><img src="http://via.placeholder.com/70x70" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="#" target='_blank'><img src="http://via.placeholder.com/70x70" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="#" target='_blank'><img src="http://via.placeholder.com/70x70" alt="" className="img-fluid" /></a>
                                    </li>
                                    <li><a href="#" target='_blank'><img src="http://via.placeholder.com/70x70" alt="" className="img-fluid" /></a>
                                     </li>
                                </ul>
                            </div> 
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="bot3-wrapper" style={{padding:'10px 10px'}}>
                    <div className="container">
                        <div className="bot3 clearfix">
                            <ul className="menu-bot">
                                <li><Link to="/about-us">About</Link></li>
                                <li><Link to="/policy">Terms</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>

                            <div className="copyrights">Copyright @2024 | All Rights Reserve
                            </div>

                        </div>
                    </div>
                </div>
            </ConfigProvider>
        </>
    )
}

export default Footer;