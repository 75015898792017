const Policy = () => {
    return (
        <>
            <div id="rooms" style={{backgroundColor:"white"}}>
                <div className="container bg-white">
                    <div className="row px-3">
                        <div className="col content-part">
                            <div className="post post-full">
                                <div className="title1">Policies</div>
                                <div className="title2 mt-3">Terms and Conditions for bookings and stay at the Inn At Ocean Grove.</div>
                                <div className="post-header">
                                    <div className="post-image ">
                                        <img style={{ maxHeight: "300px", maxWidth: "500px", float: 'right' }} src="./images/p3.webp" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="post-story " >
                                    <h4 >Please Read the Following Important Policies of the Inn At Ocean Grove:</h4>
                                    <div className="post-story-body mt-3">
                                        <ul>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">BOOKING FEE: Deposit Required</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">CANCELLATION IS A STRICT POLICY:</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">Minimum $75.00 service fee applies to all cancellations regardless of the cancellation date.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g"><strong className="x-el x-el-span c1-1q c1-1r c1-b c1-7p c1-2y c1-4b c1-7q">Charge the first night cost if cancelled within 30 days.</strong></li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g"><strong className="x-el x-el-span c1-1q c1-1r c1-b c1-7p c1-2y c1-4b c1-7q">Charge the entire stay if canceled two weeks or less prior to your stay.</strong></li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g"><strong className="x-el x-el-span c1-1q c1-1r c1-b c1-7p c1-2y c1-4b c1-7q">Giving your credit card number </strong>indicates you accept our cancellation policy.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">CLEANING SERVICE: Our rooms offer daily service cleaning with fresh towels daily <strong className="x-el x-el-span c1-1q c1-1r c1-b c1-7p c1-2y c1-4b c1-7q">upon request.</strong></li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">CLEANLINESS: Guest shall maintain the rented premises in a clean and orderly condition. If we incur additional cleaning charges, we will add the amount to your rate.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">DISTURBANCE: In consideration of neighbors and other guests, please do not knock on doors or ring doorbells. Instead, please direct inquiries or problems to the inn keepers by calling 732-775-8847. Guests shall not make any unlawful, noisy or otherwise offensive use of the rented premises, nor commit or permit any nuisance to exist thereon, nor cause damage to the rented premises, nor create any substantial interference with the rights, comfort, safety, or enjoyment to the owner or other guests. "Quiet time" is 10:00 p.m. as a courtesy to all of our guests.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">ACCESSIBILITY: The inn keeper may enter the room/unit during the day or early evening when there is a concern for the unit and at any time when there is an emergency.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">CHECK IN AND CHECK OUT Normal check in between 2:00 p.m. and 7:00 p.m. If you need assistance with luggage drop-off or late pick-up, please contact the inn keeper. Check out time is 11:00 a.m. We will give late check outs if requested and if there are no guests scheduled to come in. </li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">MINIMUM STAYS: A 2-night minimum in peak season May 13th thru September 15th. There is a 3-night minimum for all holidays.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">MAXIMUM NUMBER OF OCCUPANTS: Your rate is based on the number of guests on your confirmation. Most rooms or units allow a limited number of occupants. Please contact the inn keeper for approval of extra guests and applicable charges ($50.00 per guest). Additional guests may be asked to leave, or your credit card may be charged for each night the additional guest occupies the room.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">KEYS: Missing keys are a security issue. There is a $50 charge for any set of missing keys.</li>
                                            <li className="x-el mb-1 x-el-span c1-5z c1-60 c1-b c1-c c1-d c1-e c1-f c1-g">SMOKING AND PETS: Consideration is given to guests with allergies. Pets are allowed in Cottage A, Cottage B and The Shore Apartment. Extra charges will be incurred if this policy is not honored. The charges are based on the fee for professional smoke cleaning services plus a fee for loss of business due to inability to rent to guests who have allergies.</li>
                                        </ul>

                                        {/* <blockquote className="blockquote1">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                            euismod tincidunt ut laoreet dolore magna aliquam Lorem ipsum dolor sit amet
                                            consectetuer
                                        </blockquote> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policy