import { IRoom } from "model/Room";
import { Link } from "react-router-dom";

interface Props {
  room: IRoom;
}

const RoomCard1: React.FC<Props> = ({
  room: {
    index,
    name,
    price,
    capacity,
    pet_friendly,
    description1,
    description2,
    description3,
    description4,
    description5,
    description6,
    description7,
    description8,
    description9,
    amenities,
    images
  },
}) => {
  return (
    <div className="room-wrapper">
      <div className="room-inner">
        <div className="room">
          <figure>
            <img
              src={images[0]}
              alt=""
              className="img-fluid"
            />
            <figcaption>
              <div className="txt1">{name}</div>
              <div className="txt2">START FROM {price}</div>
            </figcaption>
          </figure>
          <div className="caption">
            <div className="txt1">{name}</div>
            {/* <div className="txt2">
              39 REVEW
              <div className="small-stars">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
              </div>
            </div> */}
            <div className="txt3">
              {description1.split(" ").slice(0, 20).join(" ") + "..."}
            </div>
            {/* <div className="txt4">
              <Link to={`/room-details?${index}`}>
                VIEW DETAIL
                <i className="fa fa-caret-right" aria-hidden="true"></i>
              </Link>
            </div> */}
          </div>
          <div className="select-txt">
            <Link to={`/room-details?${index}`}>
              <span>
              VIEW DETAIL
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </Link>
          </div>
          <div className="room-icons">
            <div className="room-ic room-ic-wifi">
              <i className="fa fa-wifi" aria-hidden="true"></i>
              <div className="txt1">FREE WIFI</div>
            </div>
            {/* <div className="room-ic room-ic-person">
              <i className="fa fa-user" aria-hidden="true"></i>
              <i className="fa fa-user" aria-hidden="true"></i>
              <div className="txt1">
                MAX
                <br />
                PERSON
              </div>
            </div> */}
            <div className="room-ic room-ic-breakfast">
              <i className="fa fa-coffee" aria-hidden="true"></i>
              <div className="txt1">
                BREAKFAST
                <br />
                INCLUDED
              </div>
            </div>
            <div className="room-ic room-ic-left">
              <div className="txt0">{capacity}</div>
              <div className="txt1">
                MAX
                <br />
                PERSON
              </div>
            </div>
            <div className="room-ic room-ic-refund">
              <i className="fa fa-tags" aria-hidden="true"></i>
              <div className="txt1">NO REFUND</div>
            </div>
            {/* <div className="room-price">
              <div className="txt1">
                <span>{price}</span>
              </div>
              <div className="txt2">PER NIGHT</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard1;
