import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import data from '../assets/room_details.json';
const Bestplaces = () => {
    return (
        <>
            <div className="container mt-5">
                <div className="title1">
                    BEST PLACES AROUND THE INN AT OCEAN GROVE
                </div>
                <div className="title2">FIND YOUR PLACE</div>
            </div>
            <div id="best-places" className='pt-1'>
                <Swiper
                    spaceBetween={50}
                    loop={true}
                    slidesPerView={1}
                    // grabCursor={true}
                    breakpoints={{
                        700: {
                            spaceBetween: 20,
                            slidesPerView: 1.5
                        }
                    }}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    style={{ minHeight: '550px' }}
                >
                    {
                        data.BestPlaces.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="owl-carousel owl-carousel-wide" style={{ margin: '20px 50px' }}>
                                        <div className="item">
                                            <div className="place-wrapper">
                                                <div className="place-inner">
                                                    <div className="place clearfix">
                                                        <div className="caption">
                                                            <div className="txt1"></div>
                                                            <div className="text-block1">
                                                                <div className="text-block1-inner">
                                                                    <div className="txt2">{item.name}</div>
                                                                    <div className="txt3"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                                        <a href={item.address_link} target='_blank' style={{ color: 'black', textDecoration: 'none' }}> {item.address}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="txt4">
                                                                {item.description}
                                                            </div>
                                                            <div className="txt5">
                                                                <div className="rest1 clearfix">
                                                                    <div className="rest1-caption">
                                                                        <div className="rest1-txt1" style={{ fontWeight: '400' }}><a style={{ textDecoration: 'none' }} href={item.link} target='_blank'>More Details <i className="fa-solid fa-caret-right"></i></a> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <figure  className="figure">
                                                            <img style={{maxHeight:"530px"}} src={item.image} alt="" className="img-fluid" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </>
    );
}

export default Bestplaces;