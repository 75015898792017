import slide1 from '../styles/images/slide-ic1.png';
import slide2 from '../styles/images/slide-ic2.png';
import slide3 from '../styles/images/slide-ic3.png';
import slide4 from '../styles/images/slide-ic4.png';
import mainLogo from '../styles/images/newlogo2.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import {Link} from 'react-router-dom';
const slideImages = [
    {
        url: 'https://res.cloudinary.com/hotelinn/image/upload/f_auto,q_auto,b_black,o_70/v1/hotelinn/home/home1',
        caption: 'Slide 1'
    },
    {
        url: 'https://res.cloudinary.com/hotelinn/image/upload/f_auto,q_auto,b_black,o_70/v1/hotelinn/home/home2',
        caption: 'Slide 2'
    },
    {
        url: 'https://res.cloudinary.com/hotelinn/image/upload/f_auto,q_auto,b_black,o_70/v1/hotelinn/home/home3',
        caption: 'Slide 3'
    },
    {
        url: 'https://res.cloudinary.com/hotelinn/image/upload/f_auto,q_auto,b_black,o_70/v1/hotelinn/home/home4',
        caption: 'Slide 4'
    }
];
const Header = () => {
    return (
        <>
            <div id="home-inner" className="home-inner">
                <div id="slides_wrapper">
                    <div id="slides">
                        <Swiper
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{ delay: 4000 }}
                            modules={[Pagination, Autoplay]}
                        >
                            {slideImages.map((slideImage, index) => (
                                <SwiperSlide  key={index} style={{height: '750px'}}>
                                    <img src= {slideImage.url} style={{display: 'block', width:'100%', objectFit:'cover', height: "inherit",backgroundColor:"#464646"}} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="slide-text-wrapper">
                    <div className="container">
                        <div className="slide-text clearfix">
                            <div className="img1">
                                {/* <img src={mainLogo} alt="" className="img-fluid" style={{height:'40px'}} /> */}
                                <div  ><span><h4  style={{color:"white", fontWeight:'bold'}}>THE INN AT OCEAN GROVE</h4></span> </div>
                            </div>
                            {/* <div  className="txt1"><span><h1  style={{color:"white", fontWeight:'bold'}}>THE INN AT <br/>OCEAN GROVE</h1></span>  */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="slide-buttons-wrapper">
                    <div className="container">
                        <div className="slide-buttons clearfix">
                            <div className="slide-button">
                                <Link to='/room-list'>
                                    <div className="ic"><img src={slide1} alt="" className="img-fluid" /></div>
                                    <div className="hr"></div>
                                    <div className="txt1">Rooms</div>
                                </Link>
                            </div>
                            {/* <div className="slide-button">
                                <a href="#">
                                    <div className="ic"><img src={slide2} alt="" className="img-fluid" /></div>
                                    <div className="hr"></div>
                                    <div className="txt1">Breakfast</div>
                                </a>
                            </div> */}
                            {/* <div className="slide-button">
                                <a href="#">
                                    <div className="ic"><img src={slide3} alt="" className="img-fluid" /></div>
                                    <div className="hr"></div>
                                    <div className="txt1">Amenities</div>
                                </a>
                            </div> */}
                            <div className="slide-button">
                                <Link to={'/contact'}>
                                    <div className="ic"><img src={slide4} alt="" className="img-fluid" /></div>
                                    <div className="hr"></div>
                                    <div className="txt1">Contact Us</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;