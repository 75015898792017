import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "api/agent";
import { LoginForm, User } from "model/User";
import { NavigateFunction } from "react-router-dom";
import { ToastMsg, getToken, removeToken, setToken } from "utility";
import { ClientSideEndpoint } from "utility/endpoint";

export interface LoginUser {
  userData: LoginForm,
  navigateTo: NavigateFunction
}

interface CommonState {
  userToken?: string | null,
  loggedInUserInfo?: User | {},
}

const initialState: CommonState = {
  loggedInUserInfo: {},
  userToken: getToken() || ""
};

export const loginUser = createAsyncThunk('login/callLogin', async({userData, navigateTo}: LoginUser) => {
  const user = await agent.Account.signIn(userData);
  return { user, navigateTo };
})

export const registerUser = createAsyncThunk('register/callRegister', async(userData: User ) => {
  const userId = await agent.Account.signUp(userData);
  return userId;
})

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.userToken = null;
      removeToken();
    }
  },
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { user, token } = action.payload.user;
      setToken(token);
      state.loggedInUserInfo = user;
			ToastMsg.showSuccess("Login successfully!!!");
      action.payload.navigateTo(ClientSideEndpoint.default);
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      const { userId } = action.payload;
      state.loggedInUserInfo = userId;
    })
  }
});

export const { logoutUser } = commonSlice.actions;

export default commonSlice.reducer;
