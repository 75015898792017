import axios from "axios";
import { EndPoints } from "../utility/endpoint";
import { LoginForm, LoginUser, RegisterUser, User } from "../model/User";

axios.defaults.baseURL = EndPoints.BaseURL;

// Note: Will use in future when we call API's
// axios.interceptors.request.use((config) => {
//     delete axios.defaults.headers.common["Authorization"];
//     const token = store.commonStore.token;
//     if (token) config.headers!.Authorization = `Bearer ${token}`;
//     return config;
// });

// const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
  get: <T>(url: string) =>
    axios.get<T>(url).then((responseBody) => responseBody.data),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then((responseBody) => responseBody.data),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(url, body).then((responseBody) => responseBody.data),
  del: <T>(url: string) =>
    axios.delete<T>(url).then((responseBody) => responseBody.data),
};

const Account = {
  signUp: (user: User) =>
    request.post<RegisterUser>(EndPoints.Account.SignUp, user),
  signIn: (user: LoginForm) =>
    request.post<LoginUser>(EndPoints.Account.SignIn, user),
};

const Room = {
  list: () => request.get<string>(EndPoints.Room.List),
};

const agent = {
  Account,
  Room,
};

export default agent;
