import PostCard from "components/PostCard"

const Post = () => {
    return (
        <>
            <PostCard />
        </>
    )
}

export default Post