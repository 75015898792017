import data from '../assets/room_details.json';
import ImageGallery from "react-image-gallery";

const Gallery = () => {
    const imgFromURL = data.ImageGallery.map(iteam => {
        return {
          original: iteam.url,
            thumbnail: iteam.url,
        }
      });
    return (
        <>
        <div  className='moment_about'>
            <div id="about" >
                <div className="container" >
                    <div className="title1" >MOMENTS</div>
                    <div className="title2">The Inn at Ocean Grove is located in the heart of the Jersey Shore, with beautiful beaches and breathtaking scenery.</div>
                    <div className="slider2-wrapper" >
                        <ImageGallery
                            infinite={true}
                            showFullscreenButton={true}
                            autoPlay={true}
                            items={imgFromURL || []}
                            showPlayButton={false}
                            disableSwipe={false}
                            showBullets={true}
                            slideInterval={7000}
                            showNav={true}
                            thumbnailPosition="bottom"
                            disableThumbnailScroll={true}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}
export default Gallery;