import { IRoom } from "model/Room";
import { Link } from "react-router-dom";
interface Props {
  room: IRoom;
  id: number;
}
const RoomCard2: React.FC<Props> = ({ id,
  room: {
    index,
    name,
    price,
    capacity,
    startsfrom,
    pet_friendly,
    description1,
    description2,
    description3,
    description4,
    description5,
    description6,
    description7,
    description8,
    // description9,
    amenities,
    images,
  }
}) => {
  const roomWrapperClassName = () => {
    if (id % 2 !== 0) return 'our-rooms-wrapper right clearfix'
    return 'our-rooms-wrapper clearfix';
  }

  return (
    <>
      <div className={roomWrapperClassName()}>
        <div className="our-rooms-left">
          <figure className="our-rooms-thumb" >
            <img
              src={images[0].replace("f_auto,q_auto", "w_0.2,h_2.0,c_crop,g_west,q_auto")}
              alt=""
              className="img-fluid"
            />
          </figure>
          <figure className="our-rooms-img">
            <img
              src={images[0]}
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="our-rooms-right">
          <div className="our-rooms-caption">
            {/* <div className="txt1">
              159 REVEW
              <div className="small-stars">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
              </div>
            </div> */}
            <div className="txt2">{name}</div>
            <div className="txt3">
              {description1.split(" ").slice(0, 12).join(" ") + " ..."}
            </div>
            <div className="txt4">
              STARTS FROM | <span>{startsfrom}</span> PER NIGHT
            </div>
            <div className="our-rooms-icons">
              <div className="our-rooms-icon">
                <figure>
                  <img src="images/ic12.png" alt="" className="img-fluid" />
                </figure>
                <div className="our-rooms-icon-txt1">Breakfast</div>
                <div className="our-rooms-icon-txt2">INCLUDES</div>
              </div>
              <div className="our-rooms-icon">
                <figure>
                  <img src="images/ic18.png" alt="" className="img-fluid" />
                </figure>
                <div className="our-rooms-icon-txt1">Air Conditioner</div>
                <div className="our-rooms-icon-txt2">INCLUDES</div>
              </div>
              <div className="our-rooms-icon">
                <figure>
                  <img src="images/ic11.png" alt="" className="img-fluid" />
                </figure>
                <div className="our-rooms-icon-txt1">
                  Free WiFi AROUND the INN AT
                  OCEAN GROVE
                </div>
                <div className="our-rooms-icon-txt2">INCLUDES</div>
              </div>
            </div>
          </div>
          <div className="our-rooms-details">
            <Link to={`/room-details?${index}`}>
              <div className="caption">
                <div className="txt1">{id}</div>
                <div className="txt2">{name}</div>
              </div>
              <div className="txt3">
                VIEW DETAIL{" "}
                <i className="fa fa-caret-right" aria-hidden="true"></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomCard2;
