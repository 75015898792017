import 'bootstrap/dist/css/bootstrap.min.css'
import AboutCard from '../components/AboutCard';
import Bestplaces from '../components/Bestplaces';
import ClientReview from '../components/ClientReview';
import Gallery from '../components/Gallery';
import Latestnews from '../components/Latestnews';
import AvailabilityFilter from '../components/AvailabilityFilter';
import Header from '../components/Header';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
import Rooms from './Rooms';
import Restaurants from 'components/Restaurants';

const Home = () => {
  return (
    <div style={{marginTop: '-110px'}}>
      <div id="home">
        <Header />
        {/* <AvailabilityFilter /> */}
      </div>
      <AboutCard />
      <Rooms isFromHomePage={true} />
      <Bestplaces />
      <ClientReview />
      <Restaurants />
      <Gallery />
      {/* <Latestnews /> */}
    </div>

  )
}

export default Home;
