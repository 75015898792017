import OurTeam from '../components/OurTeam';
import AboutCard from '../components/AboutCard';

const About = () => {

    return (
        <>
            <AboutCard />
            {/* <OurTeam /> */}
        </>
    );
}

export default About;