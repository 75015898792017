import toast from "react-hot-toast";

// Set Token to Local Storage
export const setToken = (token: string) => localStorage.setItem('token', token);

// Get Token from Local Storage
export const getToken = () => localStorage.getItem('token');

// Remove Token from Local Storage
export const removeToken = () => localStorage.removeItem('token');

// Common Toast Message
export const ToastMsg = {
  showError: (message: string) => {
    toast.error(message);
  },
  showSuccess: (message: string) => {
    toast.success(message);
  },
};
