import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./slices/AppReducer";
import roomReducer from "./slices/RoomReducer";

export const store = configureStore({
  reducer: {
    commonReducer,
    roomReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
