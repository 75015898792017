export interface IRoom {
  index: number;
  name: string;
  price: string;
  capacity: number;
  startsfrom: string;
  pet_friendly: boolean;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  description5: string;
  description6: string;
  description7: string;
  description8: string;
  description9: string;
  description: Array<string>;
  amenities: Array<Amenties>;
  images: Array<string>
}

export interface Amenties{
  name: string;
  icon: string;
}

export class Room implements IRoom {
  index: number;
  name: string;
  price: string;
  capacity: number;
  startsfrom: string;
  pet_friendly: boolean;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  description5: string;
  description6: string;
  description7: string;
  description8: string;
  description9: string;
  description: Array<string>;
  amenities: Array<Amenties>;
  images: Array<string>;

  constructor(init?: Room | IRoom) {
    this.index = init?.index ?? 0;
    this.name = init?.name ?? "";
    this.price = init?.price ?? "";
    this.startsfrom = init?.startsfrom ?? "";
    this.capacity = init?.capacity ?? 0;
    this.pet_friendly = init?.pet_friendly ?? false;
    this.description1 = init?.description1 ?? "";
    this.description2 = init?.description2 ?? "";
    this.description3 = init?.description3 ?? "";
    this.description4 = init?.description4 ?? "";
    this.description5 = init?.description5 ?? "";
    this.description6 = init?.description6 ?? "";
    this.description7 = init?.description7 ?? "";
    this.description8 = init?.description8 ?? "";
    this.description9 = init?.description9 ?? "";
    this.description = init?.description ?? [];
    this.amenities = init?.amenities ?? [];
    this.images = init?.images ?? [];
  }
}
