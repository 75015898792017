import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "api/agent";
import { IRoom, Room } from "model/Room";
import RoomsData from "../../assets/room_details.json";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface IState {
  rooms: Array<IRoom>;
}

const initialState: IState = {
  rooms: [],
};

export const getRooms = createAsyncThunk("rooms/list", async () => {
  try {
    return await agent.Room.list();
  } catch (error) {
    throw new Error("Error in getting room list");
  }
});

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRooms.pending, (state) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(getRooms.fulfilled, (state, action) => {
      const rooms = action.payload as unknown as Array<IRoom>;
      return {
        ...state,
        loading: false,
        error: null,
        rooms,
      };
    });
    builder.addCase(getRooms.rejected, (state, action) => {
      const { rooms: roomList } = RoomsData;
      return {
        ...state,
        loading: false,
        rooms: roomList.map((room) => new Room(room as unknown as IRoom)),
        error: action.error.message,
      };
    });
  },
});

export default roomSlice.reducer;

export const useSelectorRooms = () =>
  useSelector<RootState>((state) => state["roomReducer"].rooms) as Array<IRoom>;

export const useSelectorRoomByIndex = (roomIndex: number) =>
  useSelector<RootState>((state) =>
    state["roomReducer"].rooms.find((room) => room.index === roomIndex)
  ) as IRoom | undefined;
