import { Tab, Tabs } from "react-bootstrap";
import RoomCard1 from "../components/RoomCard1";
import RoomCard2 from "../components/RoomCard2";
import { useSelectorRooms } from "store/slices/RoomReducer";
import { useState } from "react";
 
interface Props {
  isFromHomePage?: boolean;
}

interface IEventTarget extends EventTarget {
  id?: string;
}

const Rooms: React.FC<Props> = ({ isFromHomePage }) => {
  const rooms = useSelectorRooms();
  const [showMore, setShowMore] = useState(false);
  const [key, setKey] = useState("List");
  const handleClick = (e: IEventTarget) => {
    setKey(e.id ?? "");
  };
  return (
    <>
      <div id="rooms">
        <div className="container">
          <div className="title1">OUR ROOMS</div>
          <div className="title2">
          The Inn at Ocean Grove has something for everyone
          </div>
          {isFromHomePage ? (
            <>
              <div className="row">
                {(showMore ? rooms : rooms?.slice(0, 3)).map((room) => (
                  <div className="col-md-4" key={room.index}>
                    <RoomCard1 room={room} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="container">
                <div className="d-flex justify-content-end mb-2">
                  <i id="Grid" className="fa fa-th" onClick={(e) => handleClick(e.target)}></i>
                  <i 
                    id="List"
                    className="fa fa-list ms-3"
                    onClick={(e) => handleClick(e.target)}
                  ></i>
                </div>
              </div>

              <Tabs
                fill
                id="rooms-view-tab"
                defaultActiveKey={key}
                activeKey={key}
                onSelect={(k) => setKey(k ?? "")}
                className="mb-3"
                style={{ display: 'none'}}
              >
                <Tab eventKey="Grid" title="Grid">
                  <div className="row">
                    {(showMore ? rooms : rooms?.slice(0, 6)).map((room) => (
                      <div className="col-md-4" key={room.index}>
                        <RoomCard1 room={room} />
                      </div>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey="List" title="List">
                  <div className="row">
                    {(showMore ? rooms : rooms?.slice(0, 6)).map((room, index) => (
                      <div className="col-12" key={room.index}>
                        <RoomCard2 room={room} id={room.index} />
                      </div>
                    ))}
                  </div>
                </Tab>
              </Tabs>
              <div
                className="text-center"
                onClick={() => setShowMore(!showMore)}
              >
                <a className="btn2">
                  {showMore ? "SEE LESS ROOMS" : "SEE MORE ROOMS"}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Rooms;
