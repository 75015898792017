import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Offcanvas } from 'react-bootstrap';
import '../styles/css/navbar.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../styles/images/logoname.svg'
import { useState, useEffect } from 'react';
import useActiveRoute from '../utility/helper';
import { ClientSideEndpoint } from '../utility/endpoint';
import { ToastMsg, getToken } from 'utility';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { logoutUser } from 'store/slices/AppReducer';

function NavBar() {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [scrollClass, setScrollClass] = useState<string>('');
  const [logoclass, setLogoclass] = useState<string>('');
  const [offcanvasShow, setOffcanvasShow] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    const handleScroll = () => {
      if (window.scrollY > 70) {
        setScrollClass('scrolled');
        setLogoclass('logo-style');
      } else {
        setScrollClass('');
        setLogoclass('');
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavClick = () => {
    setOffcanvasShow(false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    ToastMsg.showSuccess("You have been logged out!!!")
  }

  const getNavClass = () => {
    return (pathName !== '/' || isSidebar) ? 'color-black' : 'text-style'
  }

  const pathName = window.location.pathname;
  const isActiveAbout = useActiveRoute([ClientSideEndpoint.aboutUs]);
  const isActiveBlog = useActiveRoute([ClientSideEndpoint.blog]);
  const isActiveContact = useActiveRoute([ClientSideEndpoint.contact]);
  const isActiveLogin = useActiveRoute([ClientSideEndpoint.login]);
  const isActiveSignUp = useActiveRoute([ClientSideEndpoint.signup]);
  const isActivePolicy = useActiveRoute([ClientSideEndpoint.policy]);
  const isActiveRooms = useActiveRoute([ClientSideEndpoint.rooms, ClientSideEndpoint.roomDetails]);
  const isActiveHome = location.pathname === ClientSideEndpoint.default || location.pathname === ClientSideEndpoint.home ? 'active' : '';
  const isSidebar = screenWidth < 992;

  const token = getToken();

  return (
    <>
      <Navbar expand={'lg'} className={`main_nav ${scrollClass} text-style`} fixed="top">
        <Container fluid={screenWidth < 992}>
          <Navbar.Brand>
            <div className="logo_wrapper">
              <Link to={ClientSideEndpoint.default} className="logo" style={{textDecoration:'none'}}>
                {/* <img src={logo} alt="" className={`img-fluid ${logoclass} ${pathName === '/' ? '' : 'logo-style'}`} style={{maxHeight:'50px'}}/> */}
                <h3  className={`img-fluid ${logoclass} ${pathName === '/' ? '' : 'logo-style'}`} style={{maxHeight:'50px',color:"white",fontWeight:'400',fontFamily: "Dancing Script, cursive",fontStyle: "normal",textTransform: "none"}}>The Inn at Ocean Grove</h3>

                {/* <h3  className="img-fluid mt-2" > <Link to={'/'}  style={{ maxHeight:'60px',fontWeight:'bold',color:'white',textDecoration:'none'}}></Link> </h3> */}
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setOffcanvasShow(true)} aria-controls={`offcanvasNavbar-expand-${'lg'}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${'lg'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
            placement="start"
            show={offcanvasShow}
            onHide={() => setOffcanvasShow(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                <div className="logo_wrapper" >
                  <Link to={ClientSideEndpoint.default} className="logo" style={{textDecoration:'none'}}>
                    {/* <img src={logo} alt="" style={{maxHeight:'60px',fill:"black",stroke:'black',color:'black'}}   className="img-fluid " /> */}
                    <h3  className="img-fluid mt-2" style={{maxHeight:'70px',fontWeight:'400', fontFamily: "Dancing Script, cursive",fontStyle: "normal",textTransform: "none"}} >The Inn at Ocean Grove</h3>
                  </Link>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${pathName === '/' ? 'text-style' : 'color-black'} ${isSidebar} ${isActiveHome}`} as={Link} to={ClientSideEndpoint.default}>Home</Nav.Link>
                <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${getNavClass()} ${isActiveAbout}`} as={Link} to={ClientSideEndpoint.aboutUs}>About us</Nav.Link>
                <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${getNavClass()} ${isActiveRooms}`} as={Link} to={ClientSideEndpoint.rooms}>Rooms</Nav.Link>
                {/* <NavDropdown className={`nav_link d-flex align-items-center text-style ${isActiveRooms}`} title="Rooms" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={handleNavClick} as={Link} to={ClientSideEndpoint.rooms}>Rooms</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleNavClick} as={Link} to={ClientSideEndpoint.rooms}>Trading Rooms</NavDropdown.Item>
                </NavDropdown> */}
                {/* <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${getNavClass()} ${isActiveBlog}`} as={Link} to={ClientSideEndpoint.blog}>Blog</Nav.Link> */}
                <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${getNavClass()} ${isActiveContact}`} as={Link} to={ClientSideEndpoint.contact}>Contact</Nav.Link>
                <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${getNavClass()} ${isActivePolicy}`} as={Link} to={ClientSideEndpoint.policy}>POLICIES</Nav.Link>
                 <Button  className='btn-default btn-cf-submit nav_link d-flex align-items-center text-style'> <a style={{color:'white', fontWeight: 'bold',textDecoration:'none',padding:"20px 20px"}} href='https://theinnatoceangrove.client.innroad.com/' target='_blank'> Book Now</a> </Button>  
                
                {/* {!token && (
                  <>
                    <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${isActiveLogin}`} as={Link} to={ClientSideEndpoint.login}>Login</Nav.Link>
                    <Nav.Link onClick={handleNavClick} className={`nav_link d-flex align-items-center ${isActiveSignUp}`} as={Link} to={ClientSideEndpoint.signup}>SignUp</Nav.Link>
                  </>
                )}
                {token && (
                  <NavDropdown className={`nav_link d-flex align-items-center`} title="Profile" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={handleNavClick} className={`nav_link d-flex align-items-center ${isActiveSignUp}`} as={Link} to={ClientSideEndpoint.userProfile}>Edit Profile</NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogout} className={`nav_link d-flex align-items-center ${isActiveSignUp}`} as={Link} to={ClientSideEndpoint.login}>Logout</NavDropdown.Item>
                  </NavDropdown>
                )} */}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;