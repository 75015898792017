import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import AvailabilityFilter from "../components/AvailabilityFilter";
import { useLocation } from "react-router-dom";
import { useSelectorRoomByIndex } from "store/slices/RoomReducer";
import '../styles/css/roomdetails.css'
const RoomDetails = () => {
  const location = useLocation();
  const room = useSelectorRoomByIndex(parseInt(location.search.substring(1)));

  const imgFromURL = room?.images.map((item: string) => {
    return {
      original: item,
      thumbnail: item,
    }
  });

  return (
    <>
      <div id="rooms">
        <div className="container">
          <div className="title1">{room?.name}</div>
          <div className="title2"></div>
          {/* <AvailabilityFilter isFromRoomDetails={true} /> */}
          <div className="slider2-wrapper">
            <ImageGallery
              infinite={true}
              showFullscreenButton={false}
              autoPlay={true}
              items={imgFromURL || []}
              showPlayButton={false}
              disableSwipe={false}
              slideInterval={8000}
              showBullets={true}
              showNav={true}
              thumbnailPosition="right"
              disableThumbnailScroll={true}
            />
          </div>

          <div className="details-wrapper clearfix">

            {/* <div className="txt2">{room?.name}</div> */}
            <div className="title3">Room details</div>

            <div className="txt1">
              <ul>
              <li style={{ fontSize: '13px', marginTop:"15px" }}>
                  <span ></span>
                  {room?.description1}
                </li>
                {room?.description.map((ele, index) => (
                  <li key={index} style={{ fontSize: '13px', marginTop:"15px" }}>
                    <span ></span>
                    {ele}
                  </li>
                ))}
                
              </ul>
            </div>
            <div className="details-caption">
              <div className="txt1">{room?.index}</div>
              <div className="txt21">{room?.name}</div>
            </div>
          </div>

          <div className="details2-wrapper">
            <div className="row">

              <div className="col-md-8 flex">
                <div className="container">
                  <div className="row">
                    {room?.amenities.map((ele, index) => (
                      <div className="col-md-6">
                        <div className="our-rooms-icons">
                          <div className="our-rooms-icon">
                            <figure>
                            <i className={`fa-solid ${ele.icon}`} />
                              {/* {room?.amenitiesIcon[index]} */}
                            </figure>
                            <div className="our-rooms-icon-txt1">{ele.name}</div>
                            {/* <div className="our-rooms-icon-txt2">INCLUDES</div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="details2-info">
                  <div className="check1">
                    DEPOSIT: <span>REQUIRED 25%</span>
                  </div>
                  <div className="check1">
                    <span>Balance Due on Arrival</span>
                  </div>
                  <div>
                    <a href="https://theinnatoceangrove.client.innroad.com/" target="_blank" className="details2-link">
                      <div className="txt1">
                        <span> starts from </span>
                        {room?.startsfrom}
                      </div>
                      <div className="txt2">CHECK AVAILABILITY</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomDetails;
