export const ClientSideEndpoint = {
  default: "/",
  home: "/home",
  login: "/login",
  signup: "/register",
  aboutUs: "/about-us",
  contact: "/contact",
  roomDetails: "/room-details",
  blog: "/blogs",
  rooms: "/room-list",
  checkout: "/checkout",
  userProfile: "/user-profile",
  policy: "/policies",
  post: "/post",
  NotFound: "*",
};

export const EndPoints = {
  Account: {
    SignIn: "/hotel-inn/auth/login",
    SignUp: "/hotel-inn/auth/register",
  },
  Room: {
    List: "/hotel-inn/rooms",
  },
  BaseURL: "http://localhost:9000/",
};
