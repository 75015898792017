import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./Navbar";

export default function Layout() {
  return (
    <main>
      <div className="front" data-spy="scroll">
        <div style={{marginBottom: '110px'}}>
          <NavBar />
        </div>
        <Outlet />
        <Footer />
      </div>
    </main>
  )
}

