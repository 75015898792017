import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import data from '../assets/room_details.json';
import { Swiper as SwiperType } from "swiper/types";
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

const AboutCard = () => {
    const [index, setIndex] = React.useState<number>(0);
    return (
        <>
            <div id="about">
                <div className="container">
                    <div className="title1">LITTLE ABOUT US</div>
                    <div className="title2">The Inn at Ocean Grove is a charming Bed and Breakfast, located in the heart of the Jersey Shore.</div>
                    <div className="about-slider-wrapper clearfix">
                        <div className="about-slider-left">
                          
                            <div className="slider-for">
                                <Swiper
                                    loop = {true}
                                    grabCursor={true}
                                    navigation={true}
                                    autoplay={{
                                        delay: 20000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay,Navigation]}
                                    slidesPerView={1}
                                    onSlideChange={(swiper: SwiperType) => setIndex(swiper.realIndex || 0)}>
                                    {data.AboutSlideIkmages.map((slideImage, index) => (
                                        <SwiperSlide key={index}>
                                            <div >
                                                <img src={slideImage.url} alt="" className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                        <div className="about-slider-right">
                            <div className="slider-nav">
                                <div className="slider-item">
                                    <div className="txt1">
                                        {data.AboutSlidetext[index].title}
                                    </div>
                                    <div className="txt2">
                                        <p>
                                            {data.AboutSlidetext[index].description}
                                        </p>

                                        <p>
                                            {data.AboutSlidetext[index].description2}
                                        </p>
                                        
                                    </div>
                                    <div className="caption">
                                            <div className="txt1" style={{fontSize:'15px',paddingBottom:'0px'}}>{data.AboutSlidetext[index].name}</div>
                                            <div className="txt2">{data.AboutSlidetext[index].position}</div>
                                        </div>
                                    {/* <div className="txt3">
                                        {data.AboutSlidetext[index].name}
                                    </div>
                                    <div className="txt4">
                                        {data.AboutSlidetext[index].position2}
                                    </div>
                                    <div className="txt5">
                                        {data.AboutSlidetext[index].hotel}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
export default AboutCard;